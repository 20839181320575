import { useEffect, useState } from "react";
import MobileNavigation from "./components/mobile-navigation";
import SubscriptionModal from "./components/subscription-modal";
import Footer from "./layout/footer";
import Header from "./layout/header";
import { brandsData } from "./constants";
import ApplyModal from "./components/apply-modal";

const Layout = ({
  children,
  setModal,
  modal,
  applyModal,
  setApplyModal,
}: {
  children: any;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  modal: boolean;
  setApplyModal: React.Dispatch<React.SetStateAction<boolean>>;
  applyModal: boolean;
}) => {
  const onSubscribe = async () => {
    const invalidEmailRegex =
      /^(?!^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$).+$/;
    if (invalidEmailRegex.test(email) || email.trim() === "") {
      setEmail("Don't test the website");
      setErrorEmail(true);
    } else {
      setStep(step + 1);

      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/subscription`,
        // "http://localhost:3000/subscription",
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            brands: brands,
          }),
        }
      );
      setFilteredBrands(brandsData);
      setEmail("");
      setBrands([]);
    }
  };

  const onCancel = () => {
    setStep(0);
    setFilteredBrands(brandsData);
    setModal(false);
  };

  const onApplyCancel = () => {
    setApplyModal(false);
  };

  const [mobileNav, setMobileNav] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [step, setStep] = useState(0);
  const [brands, setBrands] = useState<string[]>([]);
  const [errorFeedback, setErrorFeedback] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [applyEmail, setApplyEmail] = useState("");
  const [applyFirstName, setApplyFirstName] = useState("");
  const [applyLastName, setApplyLastName] = useState("");
  const [applyPhone, setApplyPhone] = useState("");
  const [applyLocation, setApplyLocation] = useState("");
  const [applyLinks, setApplyLinks] = useState("");
  const [applyMessage, setApplyMessage] = useState("");
  const [applyCV, setApplyCV] = useState<any>();
  const [applyOther, setApplyOther] = useState<any>("");
  const [applyingFor, setApplyingFor] = useState("Social Media Manager");
  const [applied, setApplied] = useState(false);

  const [filteredBrands, setFilteredBrands] = useState(brandsData);

  const handleFeedbackSubmit = async () => {
    if (feedback.trim() === "") setErrorFeedback(true);
    else {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/feedback`,
        // "http://localhost:3000/feedback",
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ feedback: feedback }),
        }
      );
      setFeedback("");
    }
  };

  useEffect(() => {
    if (step > 2) setStep(2);
    else if (step < 0) setStep(0);
  }, [step]);

  const onApply = async () => {
    const invalidEmailRegex =
      /^(?!^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$).+$/;
    if (invalidEmailRegex.test(applyEmail) || applyEmail.trim() === "") {
      setApplyEmail("Invalid Email");
      setErrorEmail(true);
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/apply`,
        // "http://localhost:3000/apply",
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: applyEmail,
            firstName: applyFirstName,
            lastName: applyLastName,
            phone: applyPhone,
            location: applyLocation,
            links: applyLinks,
            message: applyMessage,
            cv: applyCV,
            other: applyOther,
            applyingFor: applyingFor,
          }),
        }
      );
      const res = await response.json();
      if (res?.id) {
        setApplied(true);
      }
    }
  };

  return (
    <div className="scroll-smooth">
      <div className={`${modal ? "block absolute" : `hidden`}`}>
        <SubscriptionModal
          onCancel={onCancel}
          onSubscribe={onSubscribe}
          setStep={setStep}
          setEmail={setEmail}
          setBrands={setBrands}
          brands={brands}
          email={email}
          setFilteredBrands={setFilteredBrands}
          filteredBrands={filteredBrands}
          brandsData={brandsData}
          step={step}
          setErrorEmail={setErrorEmail}
          errorEmail={errorEmail}
        />
      </div>
      <div className={`${applyModal ? "block absolute" : `hidden`}`}>
        <ApplyModal
          onCancel={onApplyCancel}
          applyEmail={applyEmail}
          applyFirstName={applyFirstName}
          applyLastName={applyLastName}
          applyPhone={applyPhone}
          applyLocation={applyLocation}
          applyLinks={applyLinks}
          applyMessage={applyMessage}
          applyCV={applyCV}
          applyOther={applyOther}
          applyingFor={applyingFor}
          applied={applied}
          setApplyEmail={setApplyEmail}
          setApplyFirstName={setApplyFirstName}
          setApplyLastName={setApplyLastName}
          setApplyPhone={setApplyPhone}
          setApplyLocation={setApplyLocation}
          setApplyLinks={setApplyLinks}
          setApplyMessage={setApplyMessage}
          setApplyCV={setApplyCV}
          setApplyOther={setApplyOther}
          onApply={onApply}
          setApplyingFor={setApplyingFor}
          setApplied={setApplied}
        />
      </div>
      <div className={`${mobileNav ? "block absolute" : `hidden`}`}>
        <MobileNavigation
          setMobileNav={setMobileNav}
          setModal={setModal}
          setApplyModal={setApplyModal}
        />
      </div>
      <div className="py-2.5 lg:py-0">
        <Header
          setMobileNav={setMobileNav}
          setModal={setModal}
          setApplyModal={setApplyModal}
          applyModal={applyModal}
        />
        {children}
        <Footer
          setModal={setModal}
          setApplyModal={setApplyModal}
          setFeedback={setFeedback}
          feedback={feedback}
          errorFeedback={errorFeedback}
          setErrorFeedback={setErrorFeedback}
          handleFeedbackSubmit={handleFeedbackSubmit}
        />
      </div>
    </div>
  );
};

export default Layout;
