import { Store } from "./store";
import vectorImage from "../assets/images/vector-image.webp";
import vectorImageLg from "../assets/images/vector-image-lg.webp";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../fade.css";
import { useState, useEffect } from "react";
import { brandsData } from "../constants";

const OurStores = ({ ...props }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 4) % brandsData.length);
    }, 5000); // Change every 3 seconds
    return () => clearInterval(interval);
  }, []);

  const currentStores = brandsData
    .slice(index, index + 4)
    .concat(brandsData.slice(0, Math.max(0, index + 4 - brandsData.length)));
  return (
    <>
      <div
        id="stores"
        className="bg-[#06170E] rounded-2xl text-white h-full mt-40"
      >
        <div className="px-6 pt-24 space-y-4 lg:space-y-0 lg:flex lg:flex-row">
          <p className="lg:w-1/4">• Our Stores</p>
          <div className="flex flex-col lg:w-full lg:pe-40">
            <p className="text-4xl font-medium lg:w-1/2">
              Discover our Targeted Brands
            </p>
            <div className="mt-2 lg:mt-0 lg:flex lg:flex-col lg:items-end lg:text-end">
              <p>Stay tuned for upcoming announcements!</p>
            </div>
            <div className="hidden lg:flex flex-col mt-8">
              <TransitionGroup>
                {currentStores.map((store, i) => (
                  <CSSTransition key={i} timeout={500}>
                    <div>
                      <Store image={store.img} title={store.title} />
                      <div className="h-0.5 bg-[#B6B6B6] w-full my-5"></div>
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-8 px-6 lg:hidden">
          <TransitionGroup>
            {currentStores.map((store, i) => (
              <CSSTransition key={i} timeout={500} classNames="fade">
                <div>
                  <Store image={store.img} title={store.title} />
                  <div className="h-0.5 bg-[#B6B6B6] w-full my-5"></div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </div>
      <div className="relative px-6 flex flex-row justify-center w-full lg:px-20">
        <svg className="absolute lg:hidden">
          <defs>
            <clipPath id="clip-path-mob" clipPathUnits="objectBoundingBox">
              <path d="M0.951 0H0.049C0.024 0 0 0.022 0 0.051V0.702C0 0.702 0 0.785 0 0.838C0 0.861 0 0.898 0 0.898V0.957C0 0.986 0.023 1 0.049 1H0.472C0.498 1 0.512 0.986 0.512 0.957V0.941C0.512 0.907 0.55 0.875 0.598 0.875H0.951C0.976 0.875 1 0.854 1 0.838V0.051C1 0.025 0.976 0 0.951 0Z" />
            </clipPath>
          </defs>
        </svg>

        <svg
          className="absolute hidden lg:block w-full h-10/12"
          viewBox="0 0 1536 483"
        >
          <defs>
            <clipPath id="clip-path-lg" clipPathUnits="objectBoundingBox">
              <path d="M0.988 0H0.012C0.005 0 0 0.018 0 0.038V0.733V0.854V0.961C0 0.981 0.005 1 0.012 1H0.863C0.87 1 0.875 0.981 0.875 0.961V0.949C0.875 0.917 0.883 0.891 0.892 0.891H0.988C0.995 0.891 1 0.872 1 0.854V0.038C1 0.017 0.995 0 0.988 0Z" />
            </clipPath>
          </defs>
        </svg>

        <img
          srcSet={`
      ${vectorImage} 480w,
      ${vectorImage} 768w,
      ${vectorImage} 1024w,
      ${vectorImage} 1280w
    `}
          sizes="(max-width: 640px) 100vw, 
           (max-width: 1024px) 768px, 
           1024px"
          src={vectorImage}
          alt=""
          height={"83.333333%"}
          className="w-full h-10/12 object-cover mt-[-1rem] lg:hidden"
          style={{ clipPath: "url(#clip-path-mob)" }}
        />

        <img
          srcSet={`
      ${vectorImageLg} 480w,
      ${vectorImageLg} 768w,
      ${vectorImageLg} 1024w,
      ${vectorImageLg} 1280w
    `}
          sizes="(max-width: 640px) 100vw, 
           (max-width: 1024px) 768px, 
           1024px"
          src={vectorImageLg}
          alt=""
          height={"83.333333%"}
          className="w-full object-cover hidden lg:block h-10/12 mt-[-1rem]"
          style={{ clipPath: "url(#clip-path-lg)" }}
        />

        <div className="absolute inset-0 top-0 flex justify-center mt-6 h-min lg:justify-start lg:px-28 xl:px-48">
          <p className="text-white font-bold text-3xl backdrop-blur-md p-4 w-3/4 bg-[rgba(3,7,18,0.5)] rounded-2xl lg:w-2/4 lg:text-2xl xl:w-1/4 xl:text-3xl">
            “ ESSE: Online Retail with Social Flair “
          </p>
        </div>

        {/* <div className="absolute bottom-0 right-6 w-5/12 lg:hidden">
          <Button
            onClick={() => {}}
            text={"Play Now"}
            backgroundColor={"#2F590F"}
            textColor={"#fff"}
          />
        </div>
        <div className="hidden absolute bottom-[-1rem] lg:block w-min right-36 lg:right-20">
          <Button
            onClick={() => {}}
            text={"Play Now"}
            width="10vw"
            backgroundColor={"#2F590F"}
            textColor={"#fff"}
          />
        </div> */}
      </div>
    </>
  );
};

export default OurStores;
