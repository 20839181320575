import Gulahmed from "./assets/images/stores/gulahmed.webp";
import AghaNoor from "./assets/images/subscription/aghanoor.webp";
import AlkaramStudio from "./assets/images/subscription/alkaram-studio.webp";
import Adidas from "./assets/images/subscription/adidas.webp";
import Almas from "./assets/images/subscription/almas.webp";
import Almirah from "./assets/images/subscription/almirah.webp";
import AmirAdnan from "./assets/images/subscription/amir-adnan.webp";
import AmmarBelal from "./assets/images/subscription/ammar-belal.webp";
import ArsalanIqbal from "./assets/images/subscription/arsalan-iqbal.webp";
import AsifaAndNabeel from "./assets/images/subscription/asifa-and-nabeel.webp";
import AsimJofa from "./assets/images/subscription/asim-jofa.webp";
import BareezeMen from "./assets/images/subscription/bareeze-men.webp";
import BeechTree from "./assets/images/subscription/beechtree.webp";
import BeyondEast from "./assets/images/subscription/beyond-east.webp";
import BonanzaSatrangi from "./assets/images/subscription/bonanza.webp";
import Breakout from "./assets/images/subscription/breakout.webp";
import Cambridge from "./assets/images/subscription/cambridge.webp";
import Change from "./assets/images/subscription/change.webp";
import Charcoal from "./assets/images/subscription/charcoal.webp";
import Charizma from "./assets/images/subscription/charizma.webp";
import Chenone from "./assets/images/subscription/chenone.webp";
import Chinyere from "./assets/images/subscription/chinyere.webp";
import Cougar from "./assets/images/subscription/cougar.webp";
import DeepakParwani from "./assets/images/subscription/deepak-parwani.webp";
import Dynasty from "./assets/images/subscription/dynasty.webp";
import Edenrobe from "./assets/images/subscription/edenrobe.webp";
import Ethnic from "./assets/images/subscription/ethnic.webp";
import Focus from "./assets/images/subscription/focus.webp";
import Forecast from "./assets/images/subscription/forecast.webp";
import Furor from "./assets/images/subscription/furor.webp";
import Generation from "./assets/images/subscription/generation.webp";
import Giordano from "./assets/images/subscription/giordano.webp";
import Haroon from "./assets/images/subscription/haroon's.webp";
import HSY from "./assets/images/subscription/hsy.webp";
import Ibraysha from "./assets/images/subscription/ibraysha.webp";
import IsmailFareed from "./assets/images/subscription/ismail-fareed.webp";
import Jockey from "./assets/images/subscription/jockey.webp";
import JunaidJamshed from "./assets/images/subscription/junaid-jamshed.webp";
import KAYSERIA from "./assets/images/subscription/kayseria.webp";
import Khaadi from "./assets/images/subscription/khaadi.webp";
import KrossKulture from "./assets/images/subscription/kross-kulture.webp";
import Lama from "./assets/images/subscription/lama.webp";
import Lawrencepur from "./assets/images/subscription/lawrencepur.webp";
import LeisureClub from "./assets/images/subscription/leisure-club.webp";
import Limelight from "./assets/images/subscription/limelight.webp";
import Essentia from "./assets/images/subscription/essentia.webp";
import Diners from "./assets/images/subscription/diners.webp";
import One from "./assets/images/subscription/one.webp";
import MinieMinors from "./assets/images/subscription/minnie-minors.webp";
import RoyalTag from "./assets/images/subscription/royal-tag.webp";
import Monark from "./assets/images/subscription/monark.webp";
import CrossStitch from "./assets/images/subscription/cross-stitch.webp";
import Ego from "./assets/images/subscription/ego.webp";
import LingerieLounge from "./assets/images/subscription/lingerie-lounge.webp";
import Elan from "./assets/images/subscription/elan.webp";
import EmraanRajput from "./assets/images/subscription/emraan-rajput.webp";
import Engine from "./assets/images/subscription/engine.webp";
import Mango from "./assets/images/subscription/mango.webp";
import Meme from "./assets/images/subscription/meme.webp";
import Salt from "./assets/images/subscription/salt-by-ideas.webp";
import Nike from "./assets/images/subscription/nike.webp";
import Oxford from "./assets/images/subscription/oxford.webp";
import ImageFabrics from "./assets/images/subscription/image-fabrics.webp";
import IttehadTextiles from "./assets/images/subscription/itehaad-textile.webp";
import Jacquard from "./assets/images/subscription/jacquard.webp";
import Uniworth from "./assets/images/subscription/uniworth.webp";
import Lulusar from "./assets/images/subscription/lulusar.webp";
import MaheenKarim from "./assets/images/subscription/maheen-karim.webp";
import MariaB from "./assets/images/subscription/maria-b.webp";
import MariaBKids from "./assets/images/subscription/maria-b-kids.webp";
import Mausummery from "./assets/images/subscription/mausummery.webp";
import MishaLakhani from "./assets/images/subscription/misha-lakhani.webp";
import MohsinNaveed from "./assets/images/subscription/mohsin-naveed-ranjha.webp";
import NidaAzwer from "./assets/images/subscription/nida-azwer.webp";
import Nimsay from "./assets/images/subscription/nimsay.webp";
import NishatLinen from "./assets/images/subscription/nishat-linen.webp";
import OrientTextiles from "./assets/images/subscription/orient-textile.webp";
import Outfitters from "./assets/images/subscription/outfitters.webp";
import PhatymaKhan from "./assets/images/subscription/phatyma-khan.webp";
import RangJa from "./assets/images/subscription/rang-ja.webp";
import SairaShakira from "./assets/images/subscription/saira-shakira.webp";
import Sapphire from "./assets/images/subscription/sapphire.webp";
import SanaSafinaz from "./assets/images/subscription/sana-safinaz.webp";
import Sanas from "./assets/images/subscription/sanas.webp";
import Naqoosh from "./assets/images/subscription/naqoosh.webp";
import SanaSalman from "./assets/images/subscription/sana-salman.webp";
import SaniaMaskatiya from "./assets/images/subscription/sania-maskatiya.webp";
import Sheep from "./assets/images/subscription/sheep.webp";
import ShirinHassan from "./assets/images/subscription/shirin-hassan.webp";
import Sifona from "./assets/images/subscription/sifona.webp";
import SoKamal from "./assets/images/subscription/so-kamal.webp";
import Saya from "./assets/images/subscription/saya.webp";
import Suffuse from "./assets/images/subscription/suffuse-by-sana-yasir.webp";
import TenaDurrani from "./assets/images/subscription/teena-durani.webp";
import TheWorkingWoman from "./assets/images/subscription/the-working-woman.webp";
import ThreadsandMotifs from "./assets/images/subscription/threads-and-motifs.webp";
import UmarSayeed from "./assets/images/subscription/umar-sayeed.webp";
import Warda from "./assets/images/subscription/warda.webp";
import WardhaSaleem from "./assets/images/subscription/wardha-saleem.webp";
import ZainabChottani from "./assets/images/subscription/zainab-chottani.webp";
import Zeen from "./assets/images/subscription/zeen-by-cambridge.webp";
import ZohaibQadeer from "./assets/images/subscription/zohaib-qadeer.webp";
import Motifz from "./assets/images/subscription/motifiz.webp";
import Zellbury from "./assets/images/subscription/zellbury.webp";
import Stoneage from "./assets/images/subscription/stoneage.webp";
import VohraSaigol from "./assets/images/subscription/vohra-and-saigol.webp";
import Equator from "./assets/images/subscription/equator.webp";
import UltraClub from "./assets/images/subscription/ultra-club.webp";
import PashminaShawls from "./assets/images/subscription/pashmina-shawls.webp";
import MTJ from "./assets/images/subscription/mtj.webp";
import UniworthBlack from "./assets/images/subscription/uniworth-black.webp";
import HangTen from "./assets/images/subscription/hang-ten.webp";

export const brandsData = [
  { title: "Khaadi", img: Khaadi },
  { title: "Limelight", img: Limelight },
  { title: "Gul Ahmed", img: Gulahmed },
  { title: "Alkaram Studio", img: AlkaramStudio },
  { title: "Ammar Belal", img: AmmarBelal },
  { title: "Amir Adnan", img: AmirAdnan },
  { title: "Focus", img: Focus },
  { title: "Essentia", img: Essentia },
  { title: "Lama", img: Lama },
  { title: "Diners", img: Diners },
  { title: "One", img: One },
  { title: "Bareeze Men", img: BareezeMen },
  { title: "Arsalan Iqbal", img: ArsalanIqbal },
  { title: "Asifa & Nabeel", img: AsifaAndNabeel },
  { title: "Agha Noor", img: AghaNoor },
  { title: "Asim Jofa", img: AsimJofa },
  { title: "BeechTree", img: BeechTree },
  { title: "Bonanza Satrangi", img: BonanzaSatrangi },
  { title: "Cambridge", img: Cambridge },
  { title: "Cougar", img: Cougar },
  { title: "Minie Minors", img: MinieMinors },
  { title: "Charcoal", img: Charcoal },
  { title: "Royal Tag", img: RoyalTag },
  { title: "Monark", img: Monark },
  { title: "Change", img: Change },
  { title: "Charizma", img: Charizma },
  { title: "ChenOne", img: Chenone },
  { title: "Chinyere", img: Chinyere },
  { title: "Cross Stitch", img: CrossStitch },
  { title: "Haroon's Designer", img: Haroon },
  { title: "Ego", img: Ego },
  { title: "Lingerie Lounge", img: LingerieLounge },
  { title: "Deepak Perwani", img: DeepakParwani },
  { title: "Dynasty Fabrics", img: Dynasty },
  { title: "Edenrobe", img: Edenrobe },
  { title: "Elan", img: Elan },
  { title: "Emraan Rajput", img: EmraanRajput },
  { title: "Engine", img: Engine },
  { title: "Breakout", img: Breakout },
  { title: "Mango", img: Mango },
  { title: "Meme", img: Meme },
  { title: "Ethnic by Outfitters", img: Ethnic },
  { title: "Almirah", img: Almirah },
  { title: "Beyond East", img: BeyondEast },
  { title: "Salt by Ideas", img: Salt },
  { title: "Ismail Fareed", img: IsmailFareed },
  { title: "Generation", img: Generation },
  { title: "Almas", img: Almas },
  { title: "Adidas", img: Adidas },
  { title: "Nike", img: Nike },
  { title: "Giordano", img: Giordano },
  { title: "Oxford", img: Oxford },
  { title: "HSY", img: HSY },
  { title: "Ibraysha", img: Ibraysha },
  { title: "Image Fabrics", img: ImageFabrics },
  { title: "Ittehad Textiles", img: IttehadTextiles },
  { title: "J. (Junaid Jamshed)", img: JunaidJamshed },
  { title: "Jacquard", img: Jacquard },
  { title: "Uniworth", img: Uniworth },
  { title: "KAYSERIA", img: KAYSERIA },
  { title: "Leisure Club", img: LeisureClub },
  { title: "Lulusar", img: Lulusar },
  { title: "Maheen Karim", img: MaheenKarim },
  { title: "Maria B.", img: MariaB },
  { title: "Maria B. Kids", img: MariaBKids },
  { title: "Mausummery", img: Mausummery },
  { title: "Misha Lakhani", img: MishaLakhani },
  { title: "Mohsin Naveed Ranjha", img: MohsinNaveed },
  { title: "Nida Azwer", img: NidaAzwer },
  { title: "Nimsay", img: Nimsay },
  { title: "Nishat Linen", img: NishatLinen },
  { title: "Orient Textiles", img: OrientTextiles },
  { title: "Outfitters", img: Outfitters },
  { title: "Phatyma Khan", img: PhatymaKhan },
  { title: "Rang Ja", img: RangJa },
  { title: "Saira Shakira", img: SairaShakira },
  { title: "Sapphire", img: Sapphire },
  { title: "Sana Safinaz", img: SanaSafinaz },
  { title: "Sana's", img: Sanas },
  { title: "Naqoosh", img: Naqoosh },
  { title: "Sana Salman", img: SanaSalman },
  { title: "Sania Maskatiya", img: SaniaMaskatiya },
  { title: "Sheep", img: Sheep },
  { title: "Shirin Hassan", img: ShirinHassan },
  { title: "Sifona", img: Sifona },
  { title: "So Kamal", img: SoKamal },
  { title: "Saya", img: Saya },
  { title: "Suffuse by Sana Yasir", img: Suffuse },
  { title: "Tena Durrani", img: TenaDurrani },
  { title: "The Working Woman", img: TheWorkingWoman },
  { title: "Threads and Motifs", img: ThreadsandMotifs },
  { title: "Umar Sayeed", img: UmarSayeed },
  { title: "Warda", img: Warda },
  { title: "Wardha Saleem", img: WardhaSaleem },
  { title: "Zainab Chottani", img: ZainabChottani },
  { title: "Zeen by Cambridge", img: Zeen },
  { title: "Zohaib Qadeer", img: ZohaibQadeer },
  { title: "Motifz", img: Motifz },
  { title: "Zellbury", img: Zellbury },
  { title: "Kross Kulture", img: KrossKulture },
  { title: "Stoneage", img: Stoneage },
  { title: "Furor", img: Furor },
  { title: "Vohra & Saigol", img: VohraSaigol },
  { title: "Forecast", img: Forecast },
  { title: "Equator", img: Equator },
  { title: "Ultra Club", img: UltraClub },
  { title: "Pashmina Shawls", img: PashminaShawls },
  { title: "MTJ", img: MTJ },
  { title: "Uniworth Black", img: UniworthBlack },
  { title: "Lawrencepur", img: Lawrencepur },
  { title: "Jockey", img: Jockey },
  { title: "Hang Ten", img: HangTen },
  { title: "Dynasty", img: Dynasty },
];
