const About = ({ ...props }) => {
  return (
    <div
      id="about"
      className="px-6 space-y-4 mt-20 lg:space-y-0 lg:flex lg:flex-row lg:space-x-20 lg:px-20 lg:w-full"
    >
      <p className="lg:whitespace-nowrap">• About</p>
      <p className="text-4xl font-bold lg:text-end lg:w-5/12 lg:text-3xl">
        At ESSE, you will find yourself in a carefully curated world of fashion.
        We offer you plenty of choices from leading apparel brands around the
        world. Quality Assurance is our promise to you, so you can shop with the
        confidence that you shall be delivered exactly what you ordered.
      </p>
      <div className="flex flex-col space-y-6 text-xl lg:w-7/12 lg:text-lg">
        <p>
          ESSE is as interactive as social media, and as convenient as
          e-commerce. Connect, share, and shop together! We focus exclusively on
          apparel. Our platform invites leading companies to market their lines,
          so you can prepare your wardrobe for this season with as many options
          as you would like on your fingertips.
        </p>
        <p>
          We facilitate direct communication between brands and customers,
          enhancing the shopping experience with real-time interactions and
          feedback. By leveraging AI, we offer personalized recommendations,
          making it easier for you to find items that match your likes,
          interests and preferences. All of us have our favourites. You can now
          not only pick and choose, but also share them, whilst connecting to
          other users with similar tastes!
        </p>
        <p>
          Have you ever wondered how your outfits will look when you wear them?
          Start with creating a virtual avatar, and visualize your your wardrobe
          in 3D here at ESSE! After that, you can drop by to our Virtual Fitting
          Room to try on your clothes on your digital self. We also use
          intuitive AI to send recommendations matching your style your way with
          our Style Match feature!
        </p>
        <p>
          Feel free to connect with fashion enthusiasts in the Fashion Hub,
          where you can share, learn and inspire those who share your aesthetic.
        </p>
      </div>
    </div>
  );
};

export default About;
