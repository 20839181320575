import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
const Tagline = ({ ...props }) => {
  return (
    <div className="mt-6 lg:mt-20">
      <Swiper
        autoplay={{
          delay: 0,
        }}
        loop
        freeMode
        modules={[Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 2000,
          },
          425: {
            slidesPerView: 1.5,
            spaceBetween: 0,
            speed: 2000,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 0,
            speed: 2000,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 50,
            speed: 4000,
          },
          1200: {
            slidesPerView: 2.4,
            spaceBetween: 50,
            speed: 2000,
          },
          1536: {
            slidesPerView: 2.5,
            spaceBetween: 50,
            speed: 3000,
          },
        }}
        slidesOffsetAfter={0}
        slidesOffsetBefore={0}
        scrollbar={false}
        className="font-medium text-7xl lg:text-9xl lg:mt-20 w-full overflow-x-auto whitespace-nowrap ps-3 text-center"
      >
        <SwiperSlide>
          <h1 className="text-center ">Explore</h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1 className="text-center  ">Shop</h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1 className="text-center ">Socalize</h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1 className="text-center ">Engage</h1>
        </SwiperSlide>
      </Swiper>
      <Swiper
        autoplay={{
          delay: 0,
        }}
        loop
        freeMode
        centeredSlides
        modules={[Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 2000,
          },
          425: {
            slidesPerView: 1.5,
            spaceBetween: 0,
            speed: 2000,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 0,
            speed: 2000,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 50,
            speed: 4000,
          },
          1200: {
            slidesPerView: 2.4,
            spaceBetween: 50,
            speed: 2000,
          },
          1536: {
            slidesPerView: 2.5,
            spaceBetween: 50,
            speed: 3000,
          },
        }}
        slidesOffsetAfter={0}
        slidesOffsetBefore={0}
        scrollbar={false}
        className="font-medium text-7xl lg:text-9xl w-full overflow-x-auto whitespace-nowrap text-center"
      >
        <SwiperSlide>
          <h1 className="text-center ">Explore</h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1 className="text-center ">Shop</h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1 className="text-center ">Socalize</h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1 className="text-center ">Engage</h1>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Tagline;
