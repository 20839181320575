import Button from "./button";
// import googleReviews from "../assets/images/google-reviews.webp";
const LikeWhatYouSee = ({ ...props }) => {
  return (
    <div className="mt-40 flex flex-col items-center text-center px-20 space-y-8 lg:items-start">
      <p className="font-bold text-4xl">Like What You See?</p>
      <Button
        text={"Choose Now"}
        backgroundColor={"#2F590F"}
        textColor={"white"}
        justify="center"
        onClick={() => {
          props.setModal(true);
        }}
      />
      {/* <img src={googleReviews} alt="" /> */}
    </div>
  );
};

export default LikeWhatYouSee;
