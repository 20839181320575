import { Feature } from "./feature";
import feature1 from "../assets/images/features/feature-1.webp";
import feature2 from "../assets/images/features/feature-2.webp";
import feature3 from "../assets/images/features/feature-3.webp";
import feature4 from "../assets/images/features/feature-4.webp";
import LikeWhatYouSee from "./like-what-you-see";

const features = [
  {
    image: feature1,
    handle: "virtual-fitting-room",
    featureNumber: "1",
    title: "Virtual Fitting Room",
    subtitle: "Virtual Try-On",
  },
  {
    image: feature2,
    handle: "ai-fashion-advisor",
    featureNumber: "2",
    title: "AI Fashion Advisor",
    subtitle: "Advanced AI Interaction",
  },
  {
    image: feature3,
    handle: "style-match",
    featureNumber: "3",
    title: "Style Match",
    subtitle: "Lookalike Searches",
  },
  {
    image: feature4,
    handle: "fashion-hub",
    featureNumber: "4",
    title: "Fashion Hub",
    subtitle: "Fashion Community and Social Interaction Interface",
  },
];

const OurFeatures = ({ ...props }) => {
  return (
    <div id="features">
      <div className="px-4 mb-12 lg:hidden">
        <p>• Our Features</p>
        <p className="text-4xl font-bold">Take a look at our Features</p>
        <p className="text-gray-600">Open any feature to read more</p>
      </div>

      <div className="space-y-6 lg:hidden">
        {features.map((feature) => (
          <Feature key={feature.featureNumber} {...feature} />
        ))}
      </div>

      <div className="hidden lg:flex flex-row justify-center space-x-10 px-20">
        <div className="flex flex-col space-y-10">
          {features.slice(0, 2).map((feature) => (
            <Feature key={feature.featureNumber} {...feature} />
          ))}
          <LikeWhatYouSee {...props} />
        </div>
        <div className="flex flex-col space-y-10">
          <div className="px-4 space-y-4 mb-12">
            <p>• Our Features</p>
            <p className="text-4xl font-bold">Take a look at our Features</p>
          </div>
          {features.slice(2).map((feature) => (
            <Feature key={feature.featureNumber} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurFeatures;
