import "./App.css";
import Layout from "./layout";
import Homepage from "./homepage";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Feature from "./features/[feature]";

function App() {
  const [modal, setModal] = useState(false);
  const [applyModal, setApplyModal] = useState(false);
  return (
    <Layout
      setModal={setModal}
      modal={modal}
      applyModal={applyModal}
      setApplyModal={setApplyModal}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Homepage setModal={setModal} modal={modal} />}
          />
          <Route
            path="/features/*"
            element={
              <>
                <head>
                  <title>Features</title>
                  <meta
                    name="description"
                    content="ESSE stands for 'Explore. Shop. Socialize. Engage'."
                  />
                </head>
                <Feature setModal={setModal} />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </Layout>
  );
}

export default App;
