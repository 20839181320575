import instagramLogo from "../assets/icons/instagram.svg";
import linkedInLogo from "../assets/icons/linkedin.svg";
import phoneSvg from "../assets/icons/phone.svg";
import emailSvg from "../assets/icons/email.svg";
import Button from "../components/button";
import Textarea from "../components/textarea";
import { useEffect, useState } from "react";

const Footer = ({ ...props }) => {
  const [sent, setSent] = useState(false);
  useEffect(() => {
    if (sent) {
      setTimeout(() => setSent(false), 5000);
    }
  }, [sent]);
  return (
    <div id="contact-us" className="mt-24 px-6 h-full lg:px-20">
      {/* <div className="relative h-full w-full "> */}
      <div className="text-white z-10 bg-[#06170E] rounded-lg h-full p-6">
        <div className="flex flex-col w-full h-full">
          <div className="pt-8 space-y-4 ms-5 me-6">
            <div className="lg:flex lg:flex-row lg:justify-between lg:mb-10">
              <div className="flex flex-col items-start">
                <div className="font-medium text-start text-4xl min-[580px]:text-7xl">
                  <h1 className="">Do you like</h1>
                  <h1> what you see?</h1>
                </div>
                <div className="mt-10 w-full">
                  <Button
                    text={"Apply Now"}
                    backgroundColor={"#fff"}
                    justify="start"
                    textColor={"#000"}
                    onClick={() => props.setApplyModal(true)}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start justify-end h-full">
                <div className="mt-12">
                  <p className="font-medium text-xl">Get in touch</p>
                </div>
                <div className="flex flex-col items-start text-[#DDDDD] space-y-6">
                  <a
                    href="tel: +923218416082"
                    className="flex flex-row space-x-4 mt-5"
                  >
                    <img src={phoneSvg} alt="" className="h-5 w-5" />
                    <p>+92 321 8416082</p>
                  </a>
                  <a
                    href="tel: +447424537776"
                    className="flex flex-row space-x-4 mt-5"
                  >
                    <img src={phoneSvg} alt="" className="h-5 w-5" />
                    <p>+44 7424537776</p>
                  </a>
                  <a
                    href="mailto:essepk.24@gmail.com"
                    className="flex flex-row space-x-4 mt-5"
                  >
                    <img src={emailSvg} alt="" className="h-4 w-5" />
                    <p>essepk.24@gmail.com</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="py-2 space-y-2">
              <Textarea
                label={"Feedback"}
                value={props.feedback}
                error={props.errorFeedback}
                placeholder={
                  "Let us know what you wish the application should have."
                }
                onChange={(e) => {
                  props.setErrorFeedback(false);
                  props.setFeedback(e.target.value);
                }}
                onSubmit={() => {
                  if (props.feedback.trim() !== "") {
                    props.setErrorFeedback(false);
                    props.handleFeedbackSubmit();
                    setSent(true);
                  } else {
                    props.setErrorFeedback(true);
                    props.setFeedback("I'm gay");
                  }
                }}
              />
              {sent && (
                <p className="text-[#ffff3]">
                  Feedback sent! Hopefully, it doesn't get lost in the
                  internet's junk drawer!
                </p>
              )}
            </div>
            <h1 className="mt-12 text-5xl font-medium">
              Experience the Future with ESSE
            </h1>
            <div className="mt-2 flex flex-row text-black space-x-2 justify-end w-full">
              <a
                href="https://www.instagram.com/esse_pk"
                title="Instagram"
                target="_blank"
                className="flex bg-[#2F590F] w-8 h-8 justify-center rounded-full "
              >
                <img
                  src={instagramLogo}
                  alt=""
                  className="h-full w-full p-2 self-center"
                />
              </a>
              <a
                target="_blank"
                title="LinkedIn"
                href="https://www.linkedin.com/company/esse2024"
                className="flex bg-[#2F590F] w-8 h-8 justify-center rounded-full"
              >
                <img
                  src={linkedInLogo}
                  alt=""
                  className="h-full w-full p-2 self-center"
                />
              </a>
              {/* <div className="flex bg-[#2F590F] w-8 h-8 justify-center rounded-full">
                <img
                  src={instagramLogo}
                  alt=""
                  className="h-full w-full p-2 self-center"
                />
              </div>
              <div className="flex bg-[#2F590F] w-8 h-8 justify-center rounded-full">
                <img
                  src={instagramLogo}
                  alt=""
                  className="h-full w-full p-2 self-center"
                />
              </div> */}
            </div>
            <div className="mt-16 flex flex-row-reverse">
              <p className="text-[#DDDDD] text-xl">All right reserved, 2024</p>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Footer;
