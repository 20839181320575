import { useEffect, useState } from "react";

type ButtonSecondaryType = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  title: string;
  color: string;
  backgroundColor: string;
  borderColor: string;
  disabled?: boolean;
  width?: string;
  id?: string;
};
const ButtonSecondary = ({
  onClick,
  title,
  color,
  backgroundColor,
  borderColor,
  width = "100%",
  disabled = false,
  id = "",
}: ButtonSecondaryType) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 1024) setIsMobile(true);
  }, []);
  return (
    <button
      id={id}
      disabled={disabled}
      className={`text-xl rounded-full h-12 border border-[${borderColor}] text-center`}
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor,
        width: isMobile ? "100%" : width,
      }}
    >
      <span style={{ color: color }}>{title}</span>
    </button>
  );
};

export default ButtonSecondary;
