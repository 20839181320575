import Button from "./button";
import fashion1 from "../assets/images/hero-section/fashion-1.webp";
import fashion2 from "../assets/images/hero-section/fashion-2.webp";
import fashion3 from "../assets/images/hero-section/fashion-3.webp";
import fashion4 from "../assets/images/hero-section/fashion-4.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
const HeroSection = ({ ...props }) => {
  return (
    <div className="mt-12">
      <h1 className="px-4 font-medium text-4xl text-center">
        Discover ESSE: Carving the Future of E-commerce
      </h1>

      <p className="mt-4 px-6 text-center text-xl">
        Welcome to ESSE, where social media meets ecommerce, to shape the future
        of online shopping experiences.
      </p>
      <div className="flex flex-row justify-center">
        <div className="flex flex-row justify-center mt-8 w-full px-2 lg:px-16">
          <Button
            text="View Products"
            backgroundColor="#2F590F"
            textColor="#fff"
            onClick={() => (window.location.href = "/#features")}
          />
          <Button
            text="Join Us"
            backgroundColor="transparent"
            textColor="#000"
            onClick={() => (window.location.href = "/#contact-us")}
          />
        </div>
      </div>
      <Swiper
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
        }}
        speed={1500}
        modules={[Autoplay]}
        loop
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
        className="flex flex-row justify-center overflow-x-auto my-20"
      >
        <SwiperSlide>
          <img
            src={fashion1}
            alt=""
            className="rounded-2xl"
            height={"400px"}
            width={"400px"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={fashion2}
            alt=""
            className="rounded-2xl"
            height={"400px"}
            width={"400px"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={fashion3}
            alt=""
            className="rounded-2xl"
            height={"400px"}
            width={"400px"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={fashion4}
            alt=""
            className="rounded-2xl"
            height={"400px"}
            width={"400px"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={fashion1}
            alt=""
            className="rounded-2xl"
            height={"400px"}
            width={"400px"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={fashion2}
            alt=""
            className="rounded-2xl"
            height={"400px"}
            width={"400px"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={fashion3}
            alt=""
            className="rounded-2xl"
            height={"400px"}
            width={"400px"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={fashion4}
            alt=""
            className="rounded-2xl"
            height={"400px"}
            width={"400px"}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HeroSection;
