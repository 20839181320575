type storeType = {
  image: string;
  title: string;
};

export const Store = ({ image, title }: storeType) => {
  return (
    <div className="flex flex-row items-center space-x-6 store lg:h-20 lg:cursor-pointer">
      <img
        src={image}
        alt=""
        className="h-14 w-14 rounded-2xl object-cover lg:h-0 lg:w-auto"
        height={"56px"}
        width={"56px"}
      />
      <h1 className="font-bold text-4xl">{title}</h1>
    </div>
  );
};
