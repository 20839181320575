import hamburger from "../assets/icons/hamburger.svg";
import Button from "../components/button";

const Header = ({ ...props }) => {
  return (
    <>
      <div className="flex flex-row justify-between px-6 items-center lg:hidden">
        <a href="/">
          <img className="h-12" src="/logo.ico" alt="logo" height={"48px"} />
        </a>
        <button onClick={() => props.setMobileNav(true)}>
          <img src={hamburger} alt="logo" className="w-7 h-5" />
        </button>
      </div>
      <div className="hidden lg:flex flex-row justify-between px-10 sticky top-0 h-20 items-center border bg-[#FFFFF3] z-50">
        <a href="/">
          {" "}
          <img className="h-16" src="/logo.ico" alt="logo" height={"64px"} />
        </a>
        <div className="flex flex-row space-x-10">
          <a href="/#features">Features</a>
          <a href="/#stores">Stores</a>
          <a href="/#about">About</a>
          <a href="/#contact-us">Contact</a>
        </div>
        <Button
          text={"Apply Now"}
          backgroundColor={"#2F590F"}
          textColor={"#fff"}
          width="12rem"
          onClick={() => props.setApplyModal(true)}
        />
      </div>
    </>
  );
};

export default Header;
