import virtualFittingRoom from "../assets/images/virtual-fitting-room.webp";
import AIFashionAdvisor from "../assets/images/ai-fashion-advisor.webp";
import FashionHub from "../assets/images/fashion-hub.webp";
import StyleMatch from "../assets/images/style-match.webp";
import Button from "../components/button";
import Tagline from "../components/tagline";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const Feature = ({ ...props }) => {
  const param = useParams();

  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [image, setImage] = useState<string>();

  useEffect(() => {
    switch (param?.["*"]) {
      case "virtual-fitting-room":
        setTitle("Virtual Fitting Room");
        setDescription(
          "Experience the future of shopping with our Virtual Fitting Room. Create a personalized AI avatar that mirrors your unique body dimensions and style. Visualize how new apparel fits and looks on your digital self before making a purchase. Plus, manage your entire physical wardrobe online, ensuring that every new addition complements your existing collection. Shopping for clothes has never been this interactive and convenient."
        );
        setImage(virtualFittingRoom);
        break;
      case "ai-fashion-advisor":
        setTitle("AI Fashion Advisor");
        setDescription(
          "Meet your new personal stylist, the AI Fashion Advisor. Utilize cutting-edge AI technology for intelligent search and tailored fashion recommendations. Simply upload your photos and let the AI analyze your style to suggest outfits and accessories that perfectly match your preferences. Discover new trends and pieces that resonate with your unique fashion sense, making every shopping experience personalized and exciting."
        );
        setImage(AIFashionAdvisor);
        break;
      case "style-match":
        setTitle("Style Match");
        setDescription(
          "Find exactly what you're looking for with Style Match. This innovative feature works like Google Lens for fashion, allowing you to upload or snap a photo of any outfit or accessory. Style Match will then find similar items available on our platform, making it effortless to shop for styles you love. Whether you're inspired by a friend's outfit or a celebrity's look, Style Match brings your fashion inspirations to life."
        );
        setImage(StyleMatch);
        break;
      case "fashion-hub":
        setTitle("Fashion Hub");
        setDescription(
          "Connect, share, and inspire within the Fashion Hub. This social platform allows you to engage with a vibrant community of fashion enthusiasts. Share your outfits, follow fashion influencers, and participate in style discussions. The Fashion Hub is where you can find inspiration, give and receive style advice, and build connections with others who share your passion for fashion. Explore trends, showcase your unique style, and become a part of the fashion conversation."
        );
        setImage(FashionHub);
        break;
      default:
        setTitle("Jo mama");
        setDescription("");
        break;
    }
  }, [param]);
  return (
    <div>
      <div className="px-6 relative mt-12 lg:px-20">
        <div className="w-full relative mb-20 z-0">
          <img src={image} alt="" className="w-full h-1/2 object-cover" />
          <div className="absolute inset-0 flex items-center justify-center h-1/2 lg:flex-col text-white lg:h-auto lg:backdrop-blur-sm lg:backdrop-brightness-75 lg:rounded-[36px]">
            <p className="text-3xl font-bold text-white text-center p-2 md:p-6 backdrop-blur-sm backdrop-brightness-75 lg:backdrop-blur-0 w-full">
              {title}
            </p>
            <div className="hidden mt-10 z-10 lg:flex lg:flex-col">
              <p className="text-center px-56 py-1">{description}</p>
              <div className="mt-8 flex flex-row justify-center">
                <Button
                  text={"Choose Now"}
                  backgroundColor={"#2F590F"}
                  textColor={"#fff"}
                  onClick={() => props.setModal(true)}
                />
                <Button
                  text={"Contact Us"}
                  backgroundColor={"#FFFFF3"}
                  textColor={"#000"}
                  onClick={() => (window.location.href = "/#contact-us")}
                />
              </div>
            </div>
          </div>
          <div className="mt-10 z-10 lg:hidden">
            <p className="text-center">{description}</p>
            <div className="mt-8 flex flex-row">
              <Button
                text={"Choose Now"}
                backgroundColor={"#2F590F"}
                textColor={"#fff"}
                onClick={() => props.setModal(true)}
              />
              <Button
                text={"Contact Us"}
                backgroundColor={"transparent"}
                textColor={"#000"}
                onClick={() => (window.location.href = "/#contact-us")}
              />
            </div>
          </div>
        </div>
      </div>
      <Tagline />
    </div>
  );
};

export default Feature;
