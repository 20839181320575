import DividerSvg from "../assets/icons/divider.svg";
import DividerSmallSvg from "../assets/icons/divider-small.svg";
type DividerType = {
  variant?: "primary" | "secondary";
};
const Divider = ({ variant = "primary" }: DividerType) => {
  return (
    <img
      src={variant === "primary" ? DividerSvg : DividerSmallSvg}
      className="w-full"
      alt=""
    />
  );
};

export default Divider;
