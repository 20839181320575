type InputType = {
  label: string;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  type?: "text" | "email" | "number" | "file" | "url";
  textBgColor?: string;
  error?: boolean;
  className?: string;
  required?: boolean;
};

const Input = ({
  label,
  placeholder,
  onChange,
  type = "text",
  textBgColor = "#FFFFF3",
  error = false,
  className = "",
  required = false,
}: InputType) => {
  return (
    <div className={className}>
      <label
        htmlFor=""
        className={`absolute bg-[${textBgColor}] mx-2 px-2 mt-[-0.5rem]`}
      >
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        onChange={onChange}
        type={type}
        name=""
        placeholder={placeholder}
        id=""
        style={{ borderColor: error ? "#ED4337" : "#D2D2D2" }}
        className={`px-6 h-16 w-full bg-transparent border`}
      />
    </div>
  );
};

export default Input;
