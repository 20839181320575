import TickSvg from "../assets/icons/tick.svg";

type BrandBoxType = {
  title: string;
  img: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  selected?: boolean;
};

const BrandBox = ({ title, img, onClick, selected = false }: BrandBoxType) => {
  return (
    <button
      onClick={onClick}
      className="h-28 w-full flex flex-row justify-center items-center text-white relative lg:h-40"
    >
      <img
        src={img}
        alt={title}
        loading="lazy"
        className="h-28 w-full object-cover rounded-md brightness-75 z-0 lg:h-40"
      />
      <div className="absolute flex flex-row space-x-2 z-10 items-center">
        {selected && <img src={TickSvg} alt="" className="h-7 w-7" />}
        <p className="text-center font-medium text-lg lg:text-2xl lg:whitespace-nowrap">
          {title}
        </p>
      </div>
      {selected && (
        <div
          className="brand-box-overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 128, 0, 0.5)", // Green with 50% opacity
            zIndex: 1, // Ensure overlay sits above image
          }}
        ></div>
      )}
    </button>
  );
};

export default BrandBox;
