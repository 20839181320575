const WhoWeAre = ({ ...props }) => {
  return (
    <>
      <div className="px-4 space-y-4 flex flex-col lg:flex-row lg:space-y-0 lg:space-x-6 mb-14">
        <p className="lg:w-1/4">• Who we are?</p>
        <p className="text-4xl font-bold lg:w-3/4">
          ESSE merges the best of social media and e-commerce into a single,
          cross-platform mobile application. Our mission is to recast how people
          explore, shop, socialize, and engage online.
        </p>
      </div>
      {/* <Swiper
        autoplay={{ delay: 0, reverseDirection: true }}
        speed={1500}
        modules={[Autoplay]}
        loop
        breakpoints={{
          0: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 100,
          },
        }}
        className="my-20 h-16 flex flex-row py-5 px-5 lg:h-20 items-center"
      >
        <SwiperSlide className="flex flex-row items-center">
          <img src={saphire} alt="" className="fill-black h-16 lg:h-20" />
        </SwiperSlide>
        <SwiperSlide className="flex flex-row items-center">
          <img src={limelight} alt="" className="fill-black h-16 lg:h-20" />
        </SwiperSlide>
        <SwiperSlide className="flex flex-row items-center">
          <img src={ethnic} alt="" className="fill-black h-16 lg:h-20" />
        </SwiperSlide>
        <SwiperSlide className="flex flex-row items-center">
          <img src={crossStitch} alt="" className="fill-black h-16 lg:h-20" />
        </SwiperSlide>
        <SwiperSlide className="flex flex-row items-center">
          <img src={edenrobe} alt="" className="fill-black h-16 lg:h-20" />
        </SwiperSlide>
        <SwiperSlide className="flex flex-row items-center">
          <img src={lulusar} alt="" className="fill-black" />
        </SwiperSlide>
        <SwiperSlide className="flex flex-row items-center">
          <img src={kayseria} alt="" className="fill-black h-16 lg:h-20" />
        </SwiperSlide>
        <SwiperSlide className="flex flex-row items-center">
          <img src={mariaB} alt="" className="fill-black" />
        </SwiperSlide>
        <SwiperSlide className="flex flex-row items-center">
          <img src={nishat} alt="" className="fill-black" />
        </SwiperSlide>
        <SwiperSlide className="flex flex-row items-center">
          <img src={khaadi} alt="" className="fill-black h-16 lg:h-20" />
        </SwiperSlide>
        <SwiperSlide className="flex flex-row items-center">
          <img src={adidas} alt="" className="fill-black h-16 lg:h-20" />
        </SwiperSlide>
        <SwiperSlide className="flex flex-row items-center">
          <img src={diners} alt="" className="fill-black h-10 lg:h-14" />
        </SwiperSlide>
      </Swiper> */}
    </>
  );
};

export default WhoWeAre;
