import CrossSvg from "../assets/icons/cross.svg";
import ButtonSecondary from "./button-secondary";
import { useEffect, useState } from "react";
import Input from "./input";
import AWS from "aws-sdk";
import { PutObjectRequest } from "aws-sdk/clients/s3";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET; // Replace with your bucket name
const REGION = process.env.REACT_APP_S3_REGION; // Asia Pacific (Sydney) region

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID, // Replace with your access key
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, // Replace with your secret access key
  region: REGION,
});

const myBucket = new AWS.S3();

type SubscriptionModalType = {
  onCancel: () => void;
  onApply: () => void;
  applyEmail: string;
  applyFirstName: string;
  applyLastName: string;
  applyPhone: string;
  applyLocation: string;
  applyLinks: string;
  applyMessage: string;
  applyCV: any;
  applyOther: any;
  applyingFor: any;
  applied: boolean;
  setApplyEmail: React.Dispatch<React.SetStateAction<string>>;
  setApplyFirstName: React.Dispatch<React.SetStateAction<string>>;
  setApplyLastName: React.Dispatch<React.SetStateAction<string>>;
  setApplyPhone: React.Dispatch<React.SetStateAction<string>>;
  setApplyLocation: React.Dispatch<React.SetStateAction<string>>;
  setApplyLinks: React.Dispatch<React.SetStateAction<string>>;
  setApplyMessage: React.Dispatch<React.SetStateAction<string>>;
  setApplyCV: React.Dispatch<React.SetStateAction<any>>;
  setApplyOther: React.Dispatch<React.SetStateAction<any>>;
  setApplyingFor: React.Dispatch<React.SetStateAction<any>>;
  setApplied: React.Dispatch<React.SetStateAction<boolean>>;
};

const ApplyModal = ({
  onCancel,
  applyEmail,
  applyFirstName,
  applyLastName,
  applyPhone,
  applyLocation,
  applyLinks,
  applyMessage,
  applyCV,
  applyOther,
  applyingFor,
  applied,
  setApplyEmail,
  setApplyFirstName,
  setApplyLastName,
  setApplyPhone,
  setApplyLocation,
  setApplyLinks,
  setApplyMessage,
  setApplyCV,
  setApplyOther,
  onApply,
  setApplyingFor,
  setApplied,
}: SubscriptionModalType) => {
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (applied) setTimeout(() => setApplied(false), 5000);
  }, [applied]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    const params: PutObjectRequest = {
      Body: file,
      Bucket: S3_BUCKET ?? "esse-pk", // Replace with your bucket name
      ContentType: file.type,
      Key: `applications/cvs/${file.name}`, // Customize file name in S3 bucket
    };

    try {
      const data = await myBucket.upload(params).promise();
      setApplyCV(data.Location);
    } catch (err) {
      console.error("Error uploading file:", err);
    }
  };

  const handleFilesChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ?? [];
    for (let i = 0; i < (files?.length ?? 0); i++) {
      if (!files[i]) {
        console.error("No file selected");
        return;
      }

      const params: PutObjectRequest = {
        Body: files[i],
        Bucket: S3_BUCKET ?? "esse-pk", // Replace with your bucket name
        ContentType: files[i].type,
        Key: `applications/others/${files[i].name}`, // Customize file name in S3 bucket
      };

      try {
        const data = await myBucket.upload(params).promise();
        setApplyOther((prev: any) => [...prev, data.Location]);
      } catch (err) {
        console.error("Error uploading file:", err);
      }
    }
  };

  useEffect(() => {
    if (
      applyEmail &&
      applyFirstName &&
      applyLastName &&
      applyCV &&
      applyPhone &&
      applyingFor
    ) {
      if (
        applyEmail != "" &&
        applyFirstName != "" &&
        applyLastName != "" &&
        applyCV != "" &&
        applyPhone != "" &&
        applyingFor != ""
      )
        setDisabled(false);
      else setDisabled(true);
    } else setDisabled(true);
  }, [
    applyEmail,
    applyFirstName,
    applyLastName,
    applyCV,
    applyPhone,
    applyingFor,
  ]);

  return (
    <div
      className="w-full h-screen fixed bg-[#FFFFF3] overflow-y-auto"
      style={{ zIndex: 60 }}
    >
      <div className="flex flex-row-reverse w-full p-4">
        <div className="w-9 h-9 rounded-full bg-[#2F590F] flex flex-row justify-center items-center">
          <button onClick={onCancel}>
            <img src={CrossSvg} alt="cross-icon" className="w-4 h-4" />
          </button>
        </div>
      </div>
      <div className="mt-6 px-5">
        <h1 className="text-4xl text-center">Apply Now</h1>
      </div>
      <div className="px-5 flex flex-col justify-between lg:px-12">
        <div>
          <div className="mt-14 flex flex-col space-y-10">
            <div className="flex flex-row justify-between w-full space-x-6">
              <Input
                className="w-1/2"
                label="First Name"
                required
                placeholder="Jon"
                onChange={(e) => {
                  setApplyFirstName(e.target.value);
                }}
              />
              <Input
                className="w-1/2"
                label="Last Name"
                required
                placeholder="Doe"
                onChange={(e) => {
                  setApplyLastName(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-row justify-between w-full space-x-6">
              <Input
                className="w-1/2"
                label="Email"
                required
                placeholder="example@gmail.com"
                onChange={(e) => {
                  setApplyEmail(e.target.value);
                }}
              />
              <Input
                className="w-1/2"
                label="Phone"
                placeholder=""
                required
                onChange={(e) => {
                  setApplyPhone(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-row justify-between w-full space-x-6">
              <Input
                className="w-1/2"
                label="Location"
                placeholder="e.g. Lahore"
                onChange={(e) => {
                  setApplyLocation(e.target.value);
                }}
              />
              <Input
                className="w-1/2"
                type="url"
                label="Other Links"
                placeholder="https://www.example.com"
                onChange={(e) => {
                  setApplyLinks(e.target.value);
                }}
              />
            </div>
            <Input
              label="Message to the hiring manager"
              placeholder=""
              onChange={(e) => {
                setApplyMessage(e.target.value);
              }}
            />
            <div className="w-full space-x-4">
              <label htmlFor="">
                Applying For <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="Applying For"
                id=""
                onChange={(e) => setApplyingFor(e.target.value)}
                className="bg-transparent h-16 border cursor-pointer m-0 sm:w-1/2 lg:w-1/6 text-center"
              >
                <option value="Social Media Manager" className="h-16">
                  Social Media Manager
                </option>
                <option value="AI Developer" className="h-16">
                  AI Developer
                </option>
                <option value="UI Designer" className="h-16">
                  UI Designer
                </option>
                <option value="Flutter Developer" className="h-16">
                  Flutter Developer
                </option>
              </select>
            </div>
            <div className="flex flex-row justify-between w-full space-x-6">
              <div className="w-1/2 self-center">
                <label htmlFor="" className={`bg-[#FFFFF3] mx-2 mt-[-0.5rem]`}>
                  Upload CV <span style={{ color: "red" }}>*</span>
                </label>

                <input
                  type="file"
                  name="uploadfile"
                  id="img"
                  className="self-end cursor-pointer"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between w-full space-x-6">
              <div className="w-1/2 self-center">
                <label htmlFor="" className={`bg-[#FFFFF3] mx-2 mt-[-0.5rem]`}>
                  Other documents
                </label>

                <input
                  type="file"
                  name="files"
                  id="files"
                  multiple
                  className="self-end cursor-pointer"
                  onChange={handleFilesChange}
                />
              </div>
            </div>
          </div>
          <div className="mt-10">
            <p className="text-gray-800">
              <span className="font-bold ">Note:</span> Your data will be stored
              in our databases for future purposes. If you want us to delete
              your data, contact us on essepk.24@gmail.com
            </p>
          </div>
        </div>
        <div className="w-full flex flex-row justify-center">
          <div className="my-10 flex flex-col w-full justify-center space-x-4 lg:w-1/2 lg:space-x-4 lg:flex-row lg:justify-center items-center">
            <ButtonSecondary
              onClick={() => {
                onApply();
                setApplied(true);
              }}
              title="Apply Now"
              disabled={disabled}
              id="next-step"
              width="45%"
              backgroundColor={disabled ? "#7F7F7F" : "#2F590F"}
              borderColor={disabled ? "#7F7F7F" : "#2F590F"}
              color={"#fff"}
            />
            <div className="relative w-0 hidden lg:block">
              {applied && (
                <p className="whitespace-nowrap">
                  Your application has been submitted successfully!
                </p>
              )}
            </div>
            {applied && (
              <p className="mt-2 whitespace-nowrap lg:hidden">
                Your application has been submitted successfully!
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyModal;
