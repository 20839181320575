type FeatureProps = {
  image: string;
  featureNumber: string;
  title: string;
  subtitle: string;
  handle: string;
};
export const Feature = ({
  image,
  featureNumber,
  title,
  subtitle,
  handle,
}: FeatureProps) => {
  return (
    <a
      className="flex flex-col space-y-8 px-4"
      // href={`/${title.replaceAll(" ", "-").toLowerCase()}`}
      href={`/features/${handle}`}
    >
      <div className="overflow-hidden rounded-2xl">
        <img
          src={image}
          alt={title}
          height={"100%"}
          width={"100%"}
          className="scale-105 lg:hover:scale-100 lg:transition-all lg:duration-300 lg:hover:brightness-50 lg:cursor-pointer"
        />
      </div>
      <div className="flex flex-col lg:flex-row lg:space-x-4 font-medium md:text-xl lg:text-2xl">
        <div className="flex flex-row space-x-4 ">
          <p>Feature no. {featureNumber}</p>
          <p>•</p>
          <p>{title}</p>
        </div>
      </div>
      {/* <h2 className="text-3xl font-bol">{subtitle}</h2> */}
    </a>
  );
};
