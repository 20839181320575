import CrossSvg from "../assets/icons/cross.svg";
import Button from "./button";
import Divider from "./divider";
type MobileNavigationType = {
  setMobileNav: React.Dispatch<React.SetStateAction<boolean>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setApplyModal: React.Dispatch<React.SetStateAction<boolean>>;
};
const MobileNavigation = ({
  setMobileNav,
  setModal,
  setApplyModal,
}: MobileNavigationType) => {
  return (
    <div className="w-full h-full z-50 fixed bg-[#FFFFF3]">
      <div className="flex flex-row-reverse w-full p-4">
        <div className="w-9 h-9 rounded-full bg-[#2F590F] flex flex-row justify-center items-center">
          <button onClick={() => setMobileNav(false)}>
            <img src={CrossSvg} alt="cross-icon" className="w-4 h-4" />
          </button>
        </div>
      </div>
      <div className="mt-6 px-5">
        <div className="flex flex-row justify-between">
          <h1 className="text-4xl">ESSE.</h1>
          <div className="space-y-4">
            <Button
              text={"Apply Now"}
              backgroundColor={"#2F590F"}
              textColor={"#fff"}
              width="12rem"
              onClick={() => setApplyModal(true)}
            />
          </div>
        </div>
        <div className="mt-4">
          <Divider />
        </div>
        <div className="mt-10 space-y-4 flex flex-col">
          <div className="space-y-4">
            <a
              href="/#features"
              onClick={() => setMobileNav(false)}
              className="text-lg"
            >
              Features
            </a>
            <Divider variant="secondary" />
          </div>
          <div className="space-y-4">
            <a
              href="/#stores"
              onClick={() => setMobileNav(false)}
              className="text-lg"
            >
              Stores
            </a>
            <Divider variant="secondary" />
          </div>
          <div className="space-y-4">
            <a
              href="/#about"
              onClick={() => setMobileNav(false)}
              className="text-lg"
            >
              About
            </a>
            <Divider variant="secondary" />
          </div>
          <div className="space-y-4">
            <a
              href="/#contact-us"
              onClick={() => {
                setMobileNav(false);
              }}
              className="text-lg"
            >
              Contact us
            </a>
            <Divider variant="secondary" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavigation;
