import About from "./components/about";
import HeroSection from "./components/hero-section";
import Tagline from "./components/tagline";
import LikeWhatYouSee from "./components/like-what-you-see";
import OurFeatures from "./components/our-features";
import OurStores from "./components/our-stores";
import WhoWeAre from "./components/who-we-are";

import "swiper/css";
const Homepage = ({ ...props }) => {
  return (
    <>
      <HeroSection {...props} />
      <WhoWeAre {...props} />
      <OurFeatures {...props} />
      <div className="lg:hidden">
        <LikeWhatYouSee {...props} />
      </div>
      <OurStores {...props} />
      <About {...props} />
      <Tagline {...props} />
    </>
  );
};

export default Homepage;
