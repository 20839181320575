import CrossSvg from "../assets/icons/cross.svg";
import BrandBox from "./brand-box";
import ArrowRight from "../assets/icons/arrow-right.svg";
import Subscribed from "../assets/images/subscribed.webp";
import ButtonSecondary from "./button-secondary";
import { useEffect, useState } from "react";
import Input from "./input";

type SubscriptionModalType = {
  onCancel: () => void;
  onSubscribe: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setErrorEmail: React.Dispatch<React.SetStateAction<boolean>>;
  errorEmail: boolean;
  email: string;
  setBrands: React.Dispatch<React.SetStateAction<string[]>>;
  brands: string[];
  setFilteredBrands: React.Dispatch<
    React.SetStateAction<
      {
        title: string;
        img: string;
      }[]
    >
  >;
  filteredBrands: {
    title: string;
    img: string;
  }[];
  brandsData: {
    title: string;
    img: string;
  }[];
};

const SubscriptionModal = ({
  onCancel,
  onSubscribe,
  setStep,
  step,
  email,
  setEmail,
  brands,
  setBrands,
  setFilteredBrands,
  filteredBrands,
  brandsData,
  setErrorEmail,
  errorEmail,
}: SubscriptionModalType) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const selectBrand = (title: string) => {
    if (brands.includes(title))
      setBrands(brands.filter((brand) => brand !== title));
    else setBrands([...brands, title]);
  };

  useEffect(() => {
    if (step === 0) setDisabled(!(brands.length >= 1));
    else setDisabled(email === "");
  }, [brands, step, email]);

  return (
    <div
      className="w-full h-screen fixed bg-[#FFFFF3] overflow-y-auto"
      style={{ zIndex: 60 }}
    >
      {step === 0 && (
        <a
          href="#next-step"
          className="animate-bounce scroll-smooth fixed z-50  bottom-20 right-12"
        >
          <img
            src={ArrowRight}
            className=" w-16 h-16 rotate-90 rounded-full sticky bg-[#FFFFF3] p-3  bottom-12 right-12"
          />
        </a>
      )}
      <div className="flex flex-row-reverse w-full p-4">
        <div className="w-9 h-9 rounded-full bg-[#2F590F] flex flex-row justify-center items-center">
          <button onClick={onCancel}>
            <img src={CrossSvg} alt="cross-icon" className="w-4 h-4" />
          </button>
        </div>
      </div>
      <div className="mt-6 px-5">
        <h1 className="text-4xl text-center">
          {step === 2 ? `Subscribed!` : `Subscribe`}{" "}
        </h1>
        <p className="text-center">
          {step === 0
            ? `Choose the brands you want on board in this platform. No we won't send you spam emails.`
            : step === 1
            ? `Drop your email to join the journey!`
            : `HAHA we'll spam you. Jk jk. Or are we?`}
        </p>
      </div>
      <div className="px-5 flex flex-col justify-between lg:px-12">
        {step === 0 ? (
          <div>
            <div className="mt-14">
              <Input
                label="Search Brands by Name"
                placeholder="e.g; Khaadi"
                onChange={(e) => {
                  setFilteredBrands((brand) =>
                    brandsData.filter((b) =>
                      b.title
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  );
                }}
              />
            </div>
            <div className="mt-10">
              <p>
                <span className="font-bold">Note:</span> Choose atleast 1 brand
                you like
              </p>
            </div>
            <div className="mt-6 space-y-2">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2 w-full">
                {filteredBrands
                  .sort((a, b) => {
                    if (a.title < b.title) {
                      return -1;
                    }
                    if (a.title > b.title) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((brand, index) => (
                    <BrandBox
                      key={index}
                      title={brand.title}
                      img={brand.img}
                      selected={brands.includes(brand.title)}
                      onClick={() => selectBrand(brand.title)}
                    />
                  ))}
              </div>
            </div>
          </div>
        ) : step === 1 ? (
          <div className="mt-32">
            <Input
              label="Email"
              type="email"
              error={errorEmail}
              placeholder="jondoe@gmail.com"
              onChange={(e) => {
                setErrorEmail(false);
                setEmail(e.target.value);
              }}
            />
          </div>
        ) : (
          <div className="flex flex-col h-full m-auto px-20 my-20">
            <img src={Subscribed} alt="" />
          </div>
        )}
        <div className="w-full flex flex-row justify-center">
          <div className="mt-10 flex flex-row w-full justify-center space-x-4 lg:w-1/2 lg:space-x-4 lg:flex lg:justify-center ">
            <ButtonSecondary
              onClick={() => {
                step !== 1 ? onCancel() : setStep(step - 1);
              }}
              title={step === 0 ? "Close" : step === 1 ? "Back" : "Continue"}
              backgroundColor={"transparent"}
              borderColor={"#010D01"}
              width="45%"
              color={"#010D01"}
            />
            {step !== 2 && (
              <ButtonSecondary
                onClick={() => {
                  step === 1 ? onSubscribe() : setStep(step + 1);
                }}
                title={step === 0 ? "Next Step" : "Subscribe"}
                disabled={disabled}
                id="next-step"
                width="45%"
                backgroundColor={disabled ? "#7F7F7F" : "#2F590F"}
                borderColor={disabled ? "#7F7F7F" : "#2F590F"}
                color={"#fff"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
