type ButtonProps = {
  text: string;
  backgroundColor: string;
  textColor: string;
  justify?: "start" | "center" | "end";
  width?: string;
  onClick: () => void;
};

const Button = ({
  text,
  backgroundColor,
  textColor,
  onClick,
  width = "full",
  justify = "center",
}: ButtonProps) => {
  return (
    <button
      className="flex flex-row items-center bg-transparent whitespace-nowrap w-full lg:w-[20vw]"
      style={{ justifyContent: justify, width: width }}
      onClick={() => onClick()}
    >
      <p
        style={{ backgroundColor, color: textColor }}
        className="rounded-3xl px-3 py-2 text-sm w-3/4"
      >
        {text}
      </p>
      <div
        style={{ backgroundColor }}
        className="arrows h-9 w-9 rounded-full ms-[-5px] flex flex-row justify-center items-center"
      >
        <div className="h-2 w-2 arrow-up">
          <svg
            viewBox="0 0 8 8"
            fill={textColor}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.5 0.999999C7.5 0.723857 7.27614 0.5 7 0.499999L2.5 0.5C2.22386 0.5 2 0.723857 2 1C2 1.27614 2.22386 1.5 2.5 1.5L6.5 1.5L6.5 5.5C6.5 5.77614 6.72386 6 7 6C7.27614 6 7.5 5.77614 7.5 5.5L7.5 0.999999ZM1.35355 7.35355L7.35355 1.35355L6.64645 0.646446L0.646447 6.64645L1.35355 7.35355Z" />
          </svg>
        </div>
        <div className="h-0 w-0 arrow-right invisible">
          <svg
            viewBox="0 0 9 8"
            fill={textColor}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.83884 4.35355C9.0341 4.15829 9.0341 3.84171 8.83884 3.64645L5.65685 0.464466C5.46159 0.269204 5.14501 0.269204 4.94975 0.464466C4.75449 0.659728 4.75449 0.976311 4.94975 1.17157L7.77818 4L4.94975 6.82843C4.75449 7.02369 4.75449 7.34027 4.94975 7.53553C5.14501 7.7308 5.46159 7.7308 5.65685 7.53553L8.83884 4.35355ZM0 4.5L8.48528 4.5V3.5L0 3.5L0 4.5Z" />
          </svg>
        </div>
      </div>
    </button>
  );
};

export default Button;
