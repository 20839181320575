import React from "react";

type TextareaType = {
  label: string;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  rows?: number;
  cols?: number;
  value: string;
  error?: boolean;
  onSubmit: () => void;
};

const Textarea = ({
  label,
  placeholder,
  onChange,
  rows = 3,
  cols = 50,
  error = false,
  value,
  onSubmit,
}: TextareaType) => {
  return (
    <div className="relative  lg:w-1/2">
      <label
        htmlFor=""
        className="absolute bg-[#06170E] mx-2 px-2 mt-[-0.5rem]"
      >
        {label}
      </label>
      <textarea
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        value={value}
        cols={cols}
        id=""
        style={{ borderColor: error ? "#ED4337" : "#D2D2D2" }}
        className={`px-6 py-3 w-full bg-transparent border rounded-md`}
      />
      <button
        onClick={onSubmit}
        className="absolute bottom-4 right-4 bg-[#2F590F] text-white rounded-full h-10 w-10 flex items-center justify-center "
      >
        ✓
      </button>
    </div>
  );
};

export default Textarea;
